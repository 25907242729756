@app-main-color: #32ccfe;

.table-narrow {
	margin: 0 auto;
}
.table-narrow > tbody > tr > td {
	padding: 3px;
}

.label-not_started {
	border: 1px solid #eaeaea;
	color: #777;
	outline: none !important;
}
.label-finished {
	background-color: #ccc;
	color: #fff;
}
.label-overdue {
	background-color: #ef4a51;
	color: #fff;
}
.label-has_issue {
	background-color: #d200ff;
	color: #fff;
}
.label-in_progress_overdue {
	background-color: #f68c4e;
	color: #fff;
}
.label-paused {
	background-color: #2977b7;
	color: #fff;
}
.label-paused_overdue {
	background-color: #e668a7;
	color: #fff;
}
.label-in_progress {
	background-color: #add038;
	color: #fff;
}

.referer-link {
    position: absolute;
    left: -6px;
    top: 6px;
    z-index: 100;
    color: #656565;
}

.aside-collapsed .user-block > .user-block-picture {
	margin: 0 0 0 9px;
}

.table .dual-cell {
	margin: -8px 0;
}
.well-narrow {
    padding: 8px;
}

.text-muted {
	color: grey;
}

.input-group-slim {
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    height: auto;
}

.ctooltip {
  position: relative;
  display: inline-block;
}

.ctooltip .ctooltiptext {
  visibility: hidden;
  min-width: 120px;
  background-color: #fff;
  text-align: center;
  padding: 7px 5px;
  border-radius: 4px;
  bottom: 20px;
  border: 1px solid #dde6e9;
 
  position: absolute;
  z-index: 2;
}

.ctooltip:hover .ctooltiptext {
  visibility: visible;
}

.offsidebar {
  display: none;
}

.offsidebar-open .offsidebar {
  display: block;
}

.btn-my-fix {
  margin: -9px 0 -1px;
}

.py-1 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mb-0 {
  margin-bottom: 0;
}