@import './custom';

.wrapper > section {
    margin-bottom: 0px !important;
}
.panel-app-bg-color .panel-heading {
    background-color: @app-main-color
}

input.ng-invalid,
select.ng-invalid,
textarea.ng-invalid,
.summernote-input.ng-invalid,
input-date-time-picker.ng-invalid input,
.select-invalid .chosen-container .chosen-single,
.select-invalid .chosen-container .chosen-choices {
    border-color: #f05050 !important;
}
.summernote-invalid .summernote + .note-editor.note-frame,
input-file.ng-invalid .input-group {
    border: 1px solid #f05050;
    border-radius: 4px;
}
form.ng-invalid button.btn.confirm {
    color: #fff;
    background-color: #f05050;
} 

.ngdialog.ngdialog-theme-default.ngdialog-wide .ngdialog-content {
    width: 960px;
}

.ngdialog.ngdialog-theme-default.ngdialog-super-wide .ngdialog-content {
    width: 95%;
    max-width: 1160px;
}

.table-breakable {
    word-break: break-all;
}

.table .checkbox {
    width: auto;
}
.nav-tabs > li > a {
    padding: 10px;
}

.language-selector ul.dropdown-menu {
    min-width: 45px;
}
.language-selector ul.dropdown-menu li {
    padding: 3px 0;
}
.language-selector ul.dropdown-menu li:hover {
    color: @app-main-color; 
}

.note-popover.popover {
    z-index: 99999;
}

.ngdialog-open {
    height: auto !important;
}

.main-preloader {
    display: none;
    position: fixed; 
    width: 100%; 
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    top: 0; 
    z-index: 10001;
    background-image: url(../img/main-preloader.gif);
    background-repeat:no-repeat;
    background-position: center center;
}

.summernote-input {
    padding: 6px 16px 16px;
}

.btn-dropdown ul {
    padding: 6px 6px 0px 6px;
}
.btn-dropdown ul li {
    margin-bottom: 6px;
}

.btn.dropdown-toggle {
    padding: 5px 16px;
}

.dialog-complex-header {
    margin-bottom: 10px;
}
.dialog-complex-header h3 {
    margin: 0;
}

.sidebar-subnav > li > a {
    padding-left: 68px;
}

.sidebar .nav > li.active {
    background-color: lighten(@app-main-color, 60%) !important;
}

.sidebar .nav > li.active > a {
    background-color: transparent !important;
}

.item-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    text-align: center;
}
.item-loader>img {
    height: 48px;
    margin-top: 48px;
}

.c-checkbox input[type=checkbox]:not(:checked) + span {
    background-color: #fff;
}
