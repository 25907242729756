/* ========================================================================
   Component: layout-animation.less
 ========================================================================== */

.wrapper > .aside {
  .transition(~"width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1)");
}
.aside-inner,
.navbar-header,
.sidebar > .nav > li {
  .transition(width .2s cubic-bezier(0.35, 0, 0.25, 1));
}
.wrapper > section {
  .transition(margin-left .2s cubic-bezier(0.35, 0, 0.25, 1));
}
.sidebar > .nav .label {
  .animation(fadeInRight 1s);
  .animation-fill-mode(both);
}
.aside-collapsed .sidebar > .nav .label,
.aside-collapsed-text .sidebar > .nav .label {
  .animation(fadeIn 1s);
}
.sidebar .nav > li > a {
 .animation(fadeInLeft .5s); 
}

.sidebar > .nav > .nav-heading, 
.sidebar > .nav > li > a > span,
.navbar-brand .brand-logo {
  .animation(fadeIn 1s); 
}

.sidebar li > a, .sidebar li > .nav-item,
.sidebar > .nav > .nav-heading {
  white-space: nowrap;
}

.aside-collapsed,
.aside-collapsed-text {
  .user-block-picture {
    .transition(width .2s cubic-bezier(0.35, 0, 0.25, 1));
  }
  .user-block {
    .transition(padding .2s cubic-bezier(0.35, 0, 0.25, 1));
  }
}